<template>
    <div id="governorates">
      <div class="container-fluid">
        <!-- Add Edit Section -->
        <div class="add-section">
          <b-modal id="slider-modal" size="xl"  cancel-title="Close" ok-title="Save" title="Sliders" @ok="submit">
            <b-form class="add-form d-grid grid-cols-1 gap-5">
                <b-row>
                    <!-- <b-col sm="12">
                        <div class="feild">
                            <label class="font-weight-bold" for="">Slider Order</label>
                            <b-form-input
                            type="text"
                            placeholder="Order Number"
                            v-model="payload.sort_order"
                            required
                            ></b-form-input>
                        </div>
                    </b-col> -->
                    <b-col sm="12" md="4">
                        <div class="feild">
                            <label class="font-weight-bold" for="">{{$t('arabic-language')}}</label>
                            <b-form-input
                            type="text"
                            class="mb-2"
                            :placeholder="$t('title')"
                            v-model="payload.ar_title"
                            required
                            ></b-form-input>
                            <b-form-textarea
                                type="text"
                                rows="4"
                                :placeholder="$t('desx')"
                                v-model="payload.ar_description"
                                required
                                ></b-form-textarea>
                        </div>
                    </b-col>
                    <b-col sm="12" md="4">
                        <div class="feild">
                            <label class="font-weight-bold" for="">{{$t('english-language')}}</label>
                            <b-form-input
                            type="text"
                            class="mb-2"
                            :placeholder="$t('title')"
                            v-model="payload.en_title"
                            required
                            ></b-form-input>
                            <b-form-textarea
                                type="text"
                                rows="4"
                                :placeholder="$t('desx')"
                                v-model="payload.en_description"
                                required
                                ></b-form-textarea>
                        </div>
                    </b-col>
                    <b-col sm="12" md="4">
                        <div class="feild">
                            <label class="font-weight-bold" for="">{{$t('kurdish-language')}}</label>
                            <b-form-input
                            type="text"
                            class="mb-2"
                            :placeholder="$t('title')"
                            v-model="payload.kr_title"
                            required
                            ></b-form-input>
                            <b-form-textarea
                                type="text"
                                rows="4"
                                :placeholder="$t('desx')"
                                v-model="payload.kr_description"
                                required
                                ></b-form-textarea>
                          </div>
                    </b-col>
                </b-row>
            </b-form>
          </b-modal>
        </div>
        <div class="table-sections">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('slider-list')}}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button variant="primary" @click="addInit" size="sm">{{$t('new-slider')}}</b-button>
            </template>
            <template v-slot:body>
              <div class="bg-light rounded p-2 mb-1" :data-id="element.id" v-for="element in sliderList" :key="element.order">
                <div class="info d-flex align-items-center justify-content-between">
                  <h6 class="font-weight-bold">
                    <i class="fas fa-grip-lines" style="color: #ccc;"></i>
                    {{ element.name }}
                  </h6>
                  <div class="actions-list d-flex align-items-center">
                    <b-button v-b-tooltip.top="$t('edit')" variant=" iq-bg-success" @click="update(element)"  size="md"><i class="ri-ball-pen-fill p-0"></i></b-button>
                    <b-button v-b-tooltip.top="$t('show-data')" variant=" iq-bg-primary" v-if="element.slider_type !== 0 && element.slider_type !== 2" @click="goToSliderData(element)"  size="md"><i class="ri-slideshow-line p-0"></i></b-button>
                    <!-- <b-button v-b-tooltip.top="'Remove'" variant=" iq-bg-danger"  size="md" @click="remove(data.item.id)"><i class="ri-delete-bin-line p-0"></i></b-button> -->
                  </div>
                </div>
                <p>{{ element.description }}</p>
              </div>
<!--              <draggable-->
<!--                  class="list-group"-->
<!--                  tag="div"-->
<!--                  v-model="sliderList"-->
<!--                  @start="drag = true"-->
<!--                  @end="testDrag">-->
<!--                <transition-group type="transition" :name="!drag ? 'flip-list' : null">-->
<!--                  -->
<!--                </transition-group>-->
<!--              </draggable>-->
              <!-- <b-table responsive :items="sliders.data" :fields="fields" class="mb-0 table-borderless">
                <template v-slot:cell(actions)="data">
                    <div class="actions-list d-flex align-items-center">
                      <b-button v-b-tooltip.top="'Edit'" variant=" iq-bg-success" @click="update(data.item)"  size="md"><i class="ri-ball-pen-fill p-0"></i></b-button>
                      <b-button v-b-tooltip.top="'Remove'" variant=" iq-bg-danger"  size="md" @click="remove(data.item.id)"><i class="ri-delete-bin-line p-0"></i></b-button>
                    </div>
                  </template>
              </b-table> -->
            </template>
          </iq-card>
        </div>
        <!-- Pagination Section -->
        <div class="pagination">
          <iq-card class="w-100 d-flex justify-content-center">
            <template v-slot:body>
              <b-pagination
                  v-model="filterData.page"
                  :total-rows="sliders.total"
                  :per-page="sliders.per_page"
              ></b-pagination>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </template>
<script>
// import draggable from 'vuedraggable'
export default {
  name: 'governorates',
  data () {
    return {
      drag: true,
      filterData: {
        page: 1,
        name: ''
      },
      payload: {
        ar_title: '',
        en_title: '',
        kr_title: '',
        ar_description: '',
        en_description: '',
        kr_description: '',
        sort_order: 1,
        slider_type: 0
      }
    }
  },
  computed: {
    draggingInfo () {
      return this.dragging ? 'under drag' : ''
    },
    sliderList: {
      get () {
        return this.sliders.data.map(el => {
          return {
            id: el.id,
            name: el[`${this.$i18n.locale}_title`],
            order: el.sort_order,
            description: el[`${this.$i18n.locale}_description`],
            slider_type: el.slider_type
          }
        })
      },
      set (value) {
        return value
      }

    },
    fields () {
      return [
        {
          label: 'Title',
          key: 'en_title'
        },
        {
          label: 'Slider Order',
          key: 'sort_order'
        },
        {
          label: 'Created Date',
          key: 'created_at'
        },
        {
          label: 'Created By',
          key: 'created_by.name'
        },
        {
          label: 'Updated Date',
          key: 'updated_at'
        },
        {
          label: 'Updated By',
          key: 'updated_by.name'
        },
        {
          label: 'Actions',
          key: 'actions'
        }
      ]
    }
  },
  methods: {
    goToSliderData (el) {
      if (el.slider_type === 1 || el.slider_type === 4) {
        this.$router.push(`/land-slider-data/${el.id}`)
      } else {
        this.$router.push(`/slider-data/${el.id}`)
      }
    },
    filtering () {
      this.filterData.page = 1
      this.getSliders(this.filterData)
    },
    addInit () {
      this.$bvModal.show('slider-modal')
    },
    update (data) {
      Object.assign(this.payload, this.sliders.data.find(el => el.id === data.id))
      this.$bvModal.show('slider-modal')
    },
    remove (id) {
      this.confermMsg(() => {
        this.removeSliders(id)
      })
    },
    testDrag (data) {
      this.updateSliders({
        id: data.item.attributes['data-id'].value,
        sort_order: data.newDraggableIndex + 1
      }).then(() => {
        this.getSliders()
      })
    },
    submit (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.slider_type = 0
      if (!this.payload.id) {
        this.payload.sort_order = this.sliders.total + 1
        this.addSliders(this.payload).then(() => {
          this.$bvModal.hide('slider-modal')
          this.payload = this.resetObject(this.payload)
        })
      } else {
        delete this.payload.slider_type
        this.updateSliders(this.payload).then(() => {
          this.payload = this.resetObject(this.payload)
          this.$bvModal.hide('slider-modal')
        })
      }
    }
  },
  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getSliders(this.filterData)
      },
      deep: true
    }
  },
  mounted () {
    this.getSliders()
  }
}
</script>
<style>

.add-form  .feild{
  padding: 10px;
}

.add-form  .feild input,
.add-form  .feild textarea{
  background-color: #f7f7f7;
}

</style>
